<template>
  <div>
    <div v-if="messageList!=null && messageList.length>0" class="chat-container background-container">
      <div class="chat-messages" ref="chatMessages">
        <div :class="{'message-hostaway': message.isIncoming, 'message-user': !message.isIncoming}"
             v-for="(message, index) in messageList" :key="index">
          <template v-if="!message.isIncoming">
            <div class="message-card-user">
              <p class="message-text">{{ removeHtmlTags(message.body) }}</p>
              <p class="message-date">{{ message.date }}</p>
            </div>
          </template>
          <template v-else>

            <div class="message-card-client">
              <p class="message-text">{{ removeHtmlTags(message.body) }}</p>
              <p class="message-date">{{ message.date }}</p>
            </div>
          </template>
          <!-- Display other message properties as needed -->
        </div>
      </div>
      <div class="chat-input mt-2">
        <b-row>
          <b-col cols="10">
            <b-input :disabled="isInputDisabled" type="text" v-model="newMessage.body"
                     placeholder="Type a message..."></b-input>
          </b-col>
          <b-col cols="1">
            <b-button :disabled="isButtonDisabled" variant="primary" size="md" @click="sendMessage">Send</b-button>
          </b-col>


        </b-row>
      </div>
    </div>
    <div v-else class="mt-0">
      <div class="text-center p-5">
        <div class="no-conversation">
          <i class="fas fa-stop mx-1 mt-4" style="color: #e75634"></i>{{ msg("No conversation yet") }}
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "HostawayChatComponent",
  props: ['contractId', 'reservationId'],
  watch: {
    contractId: {
      handler() {
        this.refresh();
      },
      immediate: true
    }, reservationId: {
      handler() {
        this.refresh();
      },
      immediate: true
    }
  },
  data: () => ({
    loading: true,
    refreshIntervalId: null,
    ready: true,
    newMessage: {body: '', reservationId: ''},
    hostawayMessage: {},
    messageList: [],
  }),
  mounted() {
    this.startRefresh(); // Start the refresh interval when the component is mounted
  },
  beforeDestroy() {
    this.stopRefresh(); // Clear the interval when the component is destroyed
  },
  computed: {
    isInputDisabled() {
      return this.messageList == null || this.messageList.length < 1;
    },
    isButtonDisabled() {
      return !this.ready;
    },
  },
  methods: {
    ...mapActions('hostaway', ['getMessagesByReservationId', 'sendHostawayMessage']),
    removeHtmlTags(text) {
      const div = document.createElement('div');
      div.innerHTML = text;
      return div.textContent || div.innerText || ''; // Extracting text content without HTML tags
    },
    startRefresh() {
      // Execute refresh every 10 seconds (10000 milliseconds)
      this.refreshIntervalId = setInterval(() => {
        this.refresh();
      }, 10000);
    },


    stopRefresh() {
      // Clear the interval to stop refreshing
      clearInterval(this.refreshIntervalId);
    },

    getMessageClasses(message, isCrm = false) {
      return {
        'message-container': true,
        'hostaway-message': !isCrm && message.isIncoming,
        'crm-message': isCrm || message.source === 'crm'
      };
    },

    sendMessage() {
      this.ready = false;
      if (this.newMessage.body.trim() !== '') {
        // Add logic to send the new message
        const newMessage = {
          body: this.newMessage.body.trim(),
          reservationId: this.reservationId,
        };
        this.sendHostawayMessage(newMessage).then(this.onMessagesRetrievedAfterSend);

      }
    },
    refresh() {
      if (this.contractId == null) {
        return;
      }
      this.getMessagesByReservationId(this.reservationId).then(this.onMessagesRetrieved);
    },
    onMessagesRetrieved(data) {
      this.hostawayMessage = data["messages"];
      this.messageList = this.hostawayMessage.result;
    },
    onMessagesRetrievedAfterSend(data) {
      this.hostawayMessage = data["messages"];
      this.messageList = this.hostawayMessage.result;
      this.newMessage = {body: '', reservationId: ''};
      this.ready = true;

    },

    formatDate(date) {
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Ensure 24-hour format
      };

      const formattedDate = new Date(date).toLocaleString('de-AT', options);
      return formattedDate.replace(',', ''); // Remove comma (optional)
    }

  }
}
</script>

<style scoped>
/* Style for the chat messages container */
.no-conversation {
  border: 2px dashed #e75634; /* Change color and thickness for the dashed border */
  padding: 0px 20px 20px;
  margin-top: 0px;
  display: inline-block; /* Ensures the border fits the content */
  border-radius: 10px; /* Optional: Add border-radius for rounded corners */
}

.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

/* Style for the message cards */
.message-card-user {
  background-color: #075E54;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  color: white;
}

.message-card-client {
  background-color: #FEA47F;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  color: #000000;
}

/* Style for hostaway messages */
.message-hostaway {
  align-self: flex-start;
}

/* Style for user messages */
.message-user {
  align-self: flex-end;
}

.message-text {
  font-weight: bold;
  font-size: 14px;
}

.message-date {
  font-size: 9px;

}

.background-container {
  /* Set the container to cover the entire viewport */
  display: flex;
  flex-direction: column; /* Or use row if your content is horizontal */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-color: rgba(255, 255, 255, 0);
  /* Add other styles or adjust as needed */
}

</style>
