<template>
  <div>

    <b-modal id="modal-approve-contract" size="lg" v-model="modals.approveContract.active" centered hide-footer
             ref="modal"
             @show="()=>modals.approveContract.signature=null">
      <template #modal-header>
        <div class="mx-auto">
          <h5>{{$t('Please read the offer and sign it below')}}</h5>
        </div>
      </template>


      <b-overlay  :show="!modals.approveContract.loaded" rounded="lg" opacity="1" variant="white"
                 style="min-height: 500px;margin-top: -20px;">
        <div>
          <iframe id="contract-data" ref="contract-data" class="w-100" style="height: 500px"></iframe>
        </div>
        <div class="position-relative" v-if="contract != null">
          <b-row no-gutters>
            <b-col cols="6">
              <div class="mt-2 ml-2">
                <p><strong>Name:</strong><span class="mx-1">{{ contract.landlord.lastname }} {{ contract.landlord.firstname }}</span></p>
                <p><strong>{{$t('Date')}}:</strong><span class="mx-1">{{ $moment().format("DD/MM/YYYY") }}</span></p>
              </div>
            </b-col>
            <b-col cols="5" class="mr-1">
              <i class="fas fa-times float-right text-danger cursor-pointer" v-b-tooltip="$t('reset signature')"
                 @click="()=>$refs.signaturePad.clearSignature()"></i>
              <VueSignaturePad ref="signaturePad"
                               v-model="modals.approveContract.signature" height="100px"
                               class="mb-2 w-100" style="margin: 0 auto" :options="{}"/>
              <div class="position-absolute text-right"
                   style="top:70px;border-bottom: 2px solid #000;width: 100%;height: 0">
                <i class="fas fa-pen " style="position: absolute; top: -15px; right: 5px;"></i>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="text-center mb-3">
          <b-button size="sm" variant="danger" class="mx-1"
                    @click="()=>modals.approveContract.active = false">
            <i class="fas fa-times"></i> Cancel
          </b-button>
          <b-button size="sm" variant="success" class="mx-1" @click="onOfferSigned()">
            <i class="fas fa-save"></i> I have read the contract and accepted
          </b-button>
        </div>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "signOffer",

  data: () => ({
    loaded: false,
    sproofWindow: {
      window: null,
      open: false,
      link: null
    },
    infoModal: {
      id: 'info-modal',
      title: '',
      content: ''
    },
    modals: {
      approveContract: {
        active: false,
        signature: null,
        loaded: false,
      },
      sproofFrame: {
        active: false,
        loaded: false,
      },
      validateContract: {
        active: false,
        codeLoading: false,
        codeSize: 4,
        codeError: false
      }
    }
  }),

  props: {
    contract: {
      required: true
    }
  },
  watch: {
    contract: {
      handler() {
        this.checkAndClosePopUp();
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('realEstateContracts', ['getOfferFile', 'signOffer']),
    requestSignContract(){
      this.showOfferFile();
    },

    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    checkAndClosePopUp() {

      if (this.contract.status === 'CLIENT_SIGNED' && this.sproofWindow.open) {

        this.sproofWindow.window.close();
        this.sproofWindow.open = false;

      }


    },

    showOfferFile(){
      let $this = this;
      this.modals.approveContract.active = true;
      this.getOfferFile({contractId: this.contract.id}).then(
        file => {
          $this.$refs["contract-data"].src = URL.createObjectURL(file.data);
          $this.$refs.signaturePad.resizeCanvas()
          $this.modals.approveContract.loaded = true;
        })
    },
    onOfferSigned() {
      let $this = this;
      this.modals.approveContract.active = false
      let signature = $this.getSignature();
      this.signOffer({contractId: this.contract.id, signature:signature}).then(data => {
        //$this.$emit('contract-signed',data)
        /* $this.contract = {...this.defaultContractObj(), ...data["contract"]};
         $this.permissions = data.permissions;
         $this.step = data.step;*/
      })
    },
    getSignature() {
      let signature = this.$refs.signaturePad.saveSignature();
      return signature.data
    },

  }
}
</script>

<style scoped>

</style>
